<div class="uk-position-top">
    <nav class="uk-navbar-container uk-navbar-transparent uk-margin-left uk-margin-right" uk-navbar>
        <div class="uk-navbar-left uk-margin-large-left">
            <a class="uk-navbar-item uk-logo" href="#"><img src="../../../../assets/img/cyom-logo-text.svg" width="80"
                    uk-svg></a>
        </div>

        <div class="uk-navbar-right uk-light uk-hidden@s">
            <a class="uk-navbar-toggle" uk-navbar-toggle-icon href=""></a>
        </div>

        <div class="uk-navbar-right uk-dark uk-visible@s">
            <ul class="uk-navbar-nav uk-margin-large-right">
                <li class="uk-margin-small-right"><a href="#" class="uk-text-secondary">Home</a>
                </li>
                <li class="uk-margin-small-right"><a href="#">Shop</a>
                </li>
                <li class="uk-margin-small-right"><a href="#">Sale</a>
                </li>
                <li class="uk-margin-small-right"><a href="#">Pages</a>
                </li>
                <li class="uk-margin-small-right"><a href="#">Blog</a>
                </li>
                <li class="uk-margin-small-right"><a href="#">Elements</a>
                </li>
                <li class="uk-margin-small-right"><a href="#">Features</a>
                </li>
            </ul>
        </div>

        <div class="uk-navbar-item">
            <ul class="uk-iconnav uk-margin-large-right">
                <li><a href="#"><span class="icon_search"></span></a></li>
                <li><a href="#"><span class="icon_heart_alt"></span></a></li>
                <li><a href="#"><span class="icon_cart_alt"></span></a></li>
            </ul>
        </div>

    </nav>
    <hr class="uk-padding-remove uk-margin-remove">
</div>