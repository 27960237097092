import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-uploader-single',
    templateUrl: './uploader-single.component.html',
    styleUrls: ['./uploader-single.component.scss'],
})
export class UploaderSingleComponent {
    @Input() folder: string;
    @Input() type = 'button';

    @Output() finish = new EventEmitter<any>();

    fileToUpload: File = null;

    handleFileInput(files: FileList) {
        this.fileToUpload = files.item(0);
    }

    onFinish(event: any) {
        this.finish.emit(event);
    }
}
