import {
    AngularFireStorage,
    AngularFireUploadTask,
} from '@angular/fire/storage';
import { from, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { switchMap } from 'rxjs/operators';

export interface FilesUploadMetadata {
    uploadProgress$: Observable<number>;
    downloadUrl$: Observable<string>;
}

@Injectable({
    providedIn: 'root',
})
export class FireStorageService {
    constructor(private readonly storage: AngularFireStorage) {}

    uploadFileAndGetMetadata(
        name: string,
        fileToUpload: File | Blob
    ): FilesUploadMetadata {
        const filePath = `${name}`;
        const uploadTask: AngularFireUploadTask = this.storage.upload(
            filePath,
            fileToUpload
        );
        return {
            uploadProgress$: uploadTask.percentageChanges(),
            downloadUrl$: this.getDownloadUrl$(uploadTask, filePath),
        };
    }

    private getDownloadUrl$(
        uploadTask: AngularFireUploadTask,
        path: string
    ): Observable<string> {
        return from(uploadTask).pipe(
            switchMap((_) => this.storage.ref(path).getDownloadURL())
        );
    }

    public getUrl(path: string): Observable<string> {
        if (path == null || path.length === 0) {
            return of(null);
        }
        return this.storage.ref(path).getDownloadURL();
    }
}
