import { LayoutWithNavbarComponent } from './modules/layout/layout-with-navbar/layout-with-navbar.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutBlankComponent } from './modules/layout/layout-blank/layout-blank.component';
import { AuthGuard } from './shared/modules/auth/auth.guard';
import { LayoutWithoutNavbarComponent } from './modules/layout/layout-without-navbar/layout-without-navbar.component';

const routes: Routes = [
    {
        path: '',
        component: LayoutWithoutNavbarComponent,
        loadChildren: () =>
            import('@modules/landings/landings.module').then(
                (m) => m.LandingsModule
            ),
    },
    {
        path: 'home',
        component: LayoutWithNavbarComponent,
        loadChildren: () =>
            import('@modules/home/home.module').then((m) => m.HomeModule),
    },
    {
        path: 'admin',
        component: LayoutWithNavbarComponent,
        loadChildren: () =>
            import('@modules/admin/admin.module').then((m) => m.AdminModule),
    },
    {
        path: 'contact',
        component: LayoutWithNavbarComponent,
        loadChildren: () =>
            import('@modules/contact/contact.module').then(
                (m) => m.ContactModule
            ),
    },
    {
        path: 'products',
        component: LayoutWithNavbarComponent,
        loadChildren: () =>
            import('@modules/products/products.module').then(
                (m) => m.ProductsModule
            ),
    },
    {
        path: 'cart',
        component: LayoutWithNavbarComponent,
        loadChildren: () =>
            import('@modules/shopping-cart/shopping-cart.module').then(
                (m) => m.ShoppingCartModule
            ),
    },
    {
        path: 'orders',
        component: LayoutWithNavbarComponent,
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('@modules/orders/orders.module').then((m) => m.OrdersModule),
    },
    {
        path: 'auth',
        component: LayoutBlankComponent,
        loadChildren: () =>
            import('@shared/modules/authentication/authentication.module').then(
                (m) => m.AuthenticationModule
            ),
    },
    {
        path: 'landings',
        component: LayoutWithNavbarComponent,
        loadChildren: () =>
            import('@modules/landings/landings.module').then(
                (m) => m.LandingsModule
            ),
    },
    {
        path: 'blog',
        component: LayoutWithNavbarComponent,
        loadChildren: () =>
            import('@modules/blog/blog.module').then((m) => m.BlogModule),
    },
    {
        path: 'users',
        component: LayoutWithNavbarComponent,
        loadChildren: () =>
            import('@modules/users/users.module').then((m) => m.UsersModule),
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
