import { Component, OnInit } from '@angular/core';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-photo-card-add',
  templateUrl: './photo-card-add.component.html',
  styleUrls: ['./photo-card-add.component.scss']
})
export class PhotoCardAddComponent implements OnInit {

  faPlusCircle = faPlusCircle;

  constructor() { }

  ngOnInit(): void {
  }

}
