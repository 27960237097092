import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faUser, faThumbsUp, faEye } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-social-card-medium',
    templateUrl: './social-card-medium.component.html',
    styleUrls: ['./social-card-medium.component.scss'],
})
export class SocialCardMediumComponent {
    faUser = faUser;
    faThumbsUp = faThumbsUp;
    faEye = faEye;
    @Input() photo;
    @Input() likesCount: number;
    @Input() isLiked: boolean;
    @Input() viewsCount;
    @Input() name;
    @Input() subtitle;

    @Output() liked = new EventEmitter<boolean>();
    @Output() toogleView = new EventEmitter<number>();

    onLiked($event) {
        this.liked.emit($event);
    }
}
