import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-logo-component',
    templateUrl: './logo.component.svg',
    styleUrls: ['./logo.component.scss'],
})
export class LogoComponent {
    @Input() color1 = 'rgb(255, 0, 0)';
    @Input() color2 = 'rgb(255, 0, 0)';
    @Input() size = '40px';
}
