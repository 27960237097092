import 'firebase/analytics';
import * as firebase from 'firebase/app';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AngularFullpageModule } from '@fullpage/angular-fullpage';
import { UserService } from '@modules/users/services/user.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthGuard } from '@shared/modules/auth/auth.guard';
import { AuthService } from '@shared/modules/auth/auth.service';
import { LaddaModule } from 'angular2-ladda';

import { QuillModule } from 'ngx-quill';
import { environment } from 'src/environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from './modules/layout/layout.module';
import { ShoppingCartService } from './modules/shopping-cart/services/shopping-cart.service';
import { SharedModule } from './shared/shared.module';

// Firebase
// Initialize Firebase
firebase.initializeApp(environment.firebaseConfig);
firebase.analytics();

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        RouterModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        AngularFullpageModule,
        NgbModule,
        FontAwesomeModule,
        AngularFireModule.initializeApp(environment.firebaseConfig),
        LayoutModule,
        SharedModule,
        QuillModule.forRoot(),
        FormsModule,
        LaddaModule,
    ],
    providers: [AuthService, UserService, AuthGuard, ShoppingCartService],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
