import { PhotoCardAddComponent } from './components/photo-card-add/photo-card-add.component';
import { UserProfileImageComponent } from './components/user-profile-image/user-profile-image.component';
import { FireStorageService } from './services/firebase/fire-storage.service';
import { JoinPipe } from './../pipes/join-pipe';
import { UploaderComponent } from './components/uploader/uploader.component';
import { LogoComponent } from './components/logo-component/logo.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutNavbarComponent } from '../modules/layout/layout-navbar/layout-navbar.component';
import { RouterModule } from '@angular/router';

import { ReplaceNullWithTextPipe } from '@app/pipes/replace-null-with-text.pipe';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SocialCardMediumComponent } from './components/social-card-medium/social-card-medium.component';
import { AuthService } from './modules/auth/auth.service';
import { UploaderSingleComponent } from './components/uploader-single/uploader-single.component';
import { UploadTaskComponent } from './components/upload-task/upload-task.component';
import { PhotoCropperComponent } from './components/photo-cropper/photo-cropper.component';

// Libs
import { ImageCropperModule } from 'ngx-image-cropper';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ImageUploaderComponent } from './components/image-uploader/image-uploader.component';
import { LikeComponent } from './components/like/like.component';

@NgModule({
    declarations: [
        ReplaceNullWithTextPipe,
        JoinPipe,
        LogoComponent,
        LayoutNavbarComponent,
        SocialCardMediumComponent,
        UploaderComponent,
        UploadTaskComponent,
        UploaderSingleComponent,
        PhotoCropperComponent,
        ImageUploaderComponent,
        UserProfileImageComponent,
        LikeComponent,
        PhotoCardAddComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        FontAwesomeModule,
        NgbModule,
        ImageCropperModule,
    ],
    exports: [
        LogoComponent,
        LayoutNavbarComponent,
        SocialCardMediumComponent,
        UploaderComponent,
        UploadTaskComponent,
        UploaderSingleComponent,
        PhotoCropperComponent,
        ReplaceNullWithTextPipe,
        JoinPipe,
        ImageUploaderComponent,
        UserProfileImageComponent,
        LikeComponent,
        PhotoCardAddComponent,
    ],
    providers: [AuthService, FireStorageService],
})
export class SharedModule {}
