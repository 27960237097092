import { Observable, Subject } from 'rxjs';
import { IBaseEntity } from './base-entity';
import {
    AngularFirestoreCollection,
    AngularFirestore,
} from '@angular/fire/firestore';
import { map, mergeMap } from 'rxjs/operators';
import { AngularFireStorage } from '@angular/fire/storage';
import * as firebase from 'firebase/app';
export interface IBaseService<T> {
    get(id: string): Observable<T>;

    list(): Observable<T[]>;

    add(item: T): Observable<T>;

    update(item: T): Observable<T>;

    delete(id: string): void;
}

export class BaseService<T extends IBaseEntity> implements IBaseService<T> {
    protected collection: AngularFirestoreCollection<T>;

    constructor(
        protected uri: string,
        protected afs: AngularFirestore,
        protected afg: AngularFireStorage
    ) {
        this.collection = this.afs.collection(this.uri);
    }

    setCollection(newUri: string): void {
        this.collection = this.afs.collection(newUri);
    }

    add(item: T, identifyUser: boolean = false): Observable<T> {
        const subject = new Subject<T>();

        console.log('[BaseService] adding: ', item);
        this.collection.add({ ...item }).then((ref) => {
            const newItem = {
                id: ref.id,
                dtCreation: firebase.firestore.FieldValue.serverTimestamp(),
                ...(item as any) /* workaround until spreads work with generic types */,
            };
            if (identifyUser === true) {
                newItem.userId = firebase.auth().currentUser.uid;
            }
            ref.set({ ...newItem });
            subject.next(newItem);
        });

        return subject.asObservable();
    }

    get authenticatedUserId(): string {
        return firebase.auth().currentUser.uid;
    }

    get(id: string): Observable<T> {
        console.log(`[BaseService][${this.uri}] get: ${id}`);
        return this.collection
            .doc<T>(id)
            .snapshotChanges()
            .pipe(
                map((action) => {
                    const data = action.payload.data() as T;
                    const id = action.payload.id;
                    return { id, ...data };
                })
            );
    }

    listByField(field: string, value: string): Observable<T[]> {
        return this.afs
            .collection(this.uri, (ref) => ref.where(field, '==', value))
            .snapshotChanges()
            .pipe(
                map((changes) => {
                    return changes.map((a) => {
                        const data = a.payload.doc.data() as T;
                        data.id = a.payload.doc.id;
                        return data;
                    });
                })
            );
    }

    getByField(field: string, value: string): Observable<T> {
        const snapshotResult = this.afs
            .collection(this.uri, (ref) =>
                ref.where(field, '==', value).limit(1)
            )
            .snapshotChanges()
            .pipe(mergeMap((clients) => clients));

        return snapshotResult.pipe(
            map((action) => {
                const data = action.payload.doc.data() as T;
                const id = action.payload.doc.id;
                return { id, ...data };
            })
        );
    }

    list(): Observable<T[]> {
        console.log(`[BaseService] list ${this.uri}`);
        return this.collection.snapshotChanges().pipe(
            map((changes) => {
                return changes.map((a) => {
                    const data = a.payload.doc.data() as T;
                    data.id = a.payload.doc.id;
                    return data;
                });
            })
        );
    }

    listOrdered(field: string, direction?: any): Observable<T[]> {
        console.log('[BaseService] list ordered');

        return this.afs
            .collection(this.uri, (ref) => ref.orderBy(field, direction))
            .snapshotChanges()
            .pipe(
                map((changes) => {
                    return changes.map((a) => {
                        const data = a.payload.doc.data() as T;
                        data.id = a.payload.doc.id;
                        return data;
                    });
                })
            );
    }

    listSubCollection(id: string, subcollection: string): Observable<T[]> {
        return this.collection
            .doc(id)
            .collection(subcollection)
            .snapshotChanges()
            .pipe(
                map((changes) => {
                    return changes.map((a) => {
                        const data = a.payload.doc.data() as T;
                        data.id = a.payload.doc.id;
                        return data;
                    });
                })
            );
    }

    update(item: T): Observable<T> {
        const subject = new Subject<T>();

        console.log('[BaseService] updating: ', item);
        this.collection
            .doc<T>(item.id)
            .set(item, { merge: true })
            .then(() => subject.next(item));

        return subject.asObservable();
    }

    delete(id: string): void {
        console.log('[BaseService] deleting: ', id);

        const docRef = this.collection.doc<T>(id);
        docRef.delete();
    }

    getImageUrl(imgRef: string): Observable<any> {
        const ref = this.afg.ref(imgRef);
        return ref.getDownloadURL();
    }

    // TENTAR adaptar o código abaixo para filtros dinâmicos
    // fonte: https://stackoverflow.com/questions/60137251/can-you-create-dynamic-firebase-queries
    // collectRecordsByFilter(table, filterArray, ref = null) {
    //     let query = this.collection;
    //     query = filterArray.reduce((accQuery, filter) => {
    //         return accQuery.where(filter.fieldRef, filter.opStr, filter.value);
    //     }, query);
    //     return query
    //         .get() //get the resulting filtered query results
    //         .then((querySnapshot) => {
    //             return Promise.resolve(
    //                 querySnapshot.docs.map((doc) => {
    //                     return {
    //                         ...doc.data(),
    //                         Id: doc.id,
    //                         ref: doc.ref,
    //                     };
    //                 })
    //             );
    //         })

    // export const fetchTourByDateAndArtist = (dateString, artist) => {
    //     //for each artist, only *one* tour bridges any particular data (except the default)
    //     const filterArray = [
    //       { fieldRef: "dateStart", opStr: "<=", value: dateString }
    //     ];
    //     return collectRecordsByFilter("Tours", filterArray, artist.ref).then(
    //       tours => {
    //         return Promise.resolve(
    //           tours.find(tour => {
    //             return moment(tour.dateEnd).isSameOrAfter(dateString);
    //           })
    //         );
    //       }
    //     );
    //   };
    // }
}
