import { BaseEntity } from '@app/shared/services/firebase/base-entity';

export class ShoppingCartItem extends BaseEntity {
    name: string;
    thumbUrl: string;
    price: number;
    quantity: number;

    // Com esse construtor é possível instanciar um novo
    // ShoppingCartItem com um objeto que tenha alguma
    // propriedade de ShoppingCarItem
    // ... () {obj => new ShoppingCartItem({...obj})
    constructor(init?: Partial<ShoppingCartItem>) {
        super();
        Object.assign(this, init);
    }

    get totalPrice() {
        return this.price * this.quantity;
    }
}
