import { Component, HostListener, OnInit } from '@angular/core';

@Component({
    selector: 'app-layout-with-navbar',
    templateUrl: './layout-with-navbar.component.html',
    styleUrls: ['./layout-with-navbar.component.scss'],
})
export class LayoutWithNavbarComponent implements OnInit {
    public innerHeight: any;
    constructor() {}

    ngOnInit(): void {
        this.innerHeight = window.innerHeight;
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.innerHeight = window.innerHeight;
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
}
