import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
    CanActivate,
    Router,
} from '@angular/router';
import { AuthService } from './auth.service';
import { tap, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {
    constructor(private auth: AuthService, private router: Router) {}

    canActivate(route, state: RouterStateSnapshot) {
        return this.auth.user$.pipe(
            map((user): boolean => {
                if (user) {
                    return true;
                }

                this.router.navigate(['/auth/login'], {
                    queryParams: { returnUrl: state.url },
                });
                return false;
            })
        );
    }
}
