export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyDSu16YxfDHWb_ZZc_UdsdA2QJ-AF6RstI',
    authDomain: 'cyom-staging.firebaseapp.com',
    databaseURL: 'https://cyom-staging.firebaseio.com',
    projectId: 'cyom-staging',
    storageBucket: 'cyom-staging.appspot.com',
    messagingSenderId: '162842842710',
    appId: '1:162842842710:web:9ca2e21a06645917bd4df7',
    measurementId: 'G-875SBTCTR3',
  },
};
