<div class="project-container">
    <div class="image-wrapper">
        <img [src]="photo.imgUrl" alt="img">
    </div>
    <div class="project-bottom">
        <div class="title-author">
            Sem nada ainda
        </div>
        <div class="like-view">
            <app-like [likesCount]="likesCount" [isActive]="isLiked" (liked)="onLiked($event)"></app-like>
        </div>
    </div>
</div>