export interface IBaseEntity {
    id?: string;
}

export class BaseEntity implements IBaseEntity {
    public constructor(init?: Partial<BaseEntity>) {
        Object.assign(this, init);
    }
    id?: string;
}
