<div class="dropzone" dropzone (hovered)="toggleHover($event)" (dropped)="onDrop($event)" [class.hovering]="isHovering">
    <div class="file">
        <label class="file-label">
            <input class="file-input" hidden multiple type="file" (change)="onDrop($event.target.files)">
            <span class="file-cta" style="cursor: pointer;">
                <span class="file-icon">
                    <i class="fa fa-upload"></i>
                </span>
                <span class="file-label">
                    Upload files...
                </span>
            </span>
        </label>
    </div>
</div>
<div>
    <h5>Arquivos</h5>
    <div *ngFor="let file of files">
        <upload-task [folder]="folder" [file]="file" (finished)="onFinish($event)"></upload-task>
    </div>
</div>