import { FireStorageService } from './../../services/firebase/fire-storage.service';
import { Component, Input } from '@angular/core';
import { AngularFireUploadTask } from '@angular/fire/storage';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { EMPTY, Observable, Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { base64ToFile } from 'ngx-image-cropper';

@Component({
    selector: 'app-photo-cropper',
    templateUrl: './photo-cropper.component.html',
    styleUrls: ['./photo-cropper.component.scss'],
})
export class PhotoCropperComponent {
    destroy$: Subject<null> = new Subject();

    croppedImage: any = '';
    fileName: string;
    submitted = false;
    uploadProgress$: Observable<number>;

    @Input() imageChangedEvent: any = '';
    @Input() folder: string;
    @Input() id: string;
    @Input() aspectRatio: number;

    task: AngularFireUploadTask;

    percentage: Observable<number>;
    snapshot: Observable<any>;
    downloadURL: string;

    uploadProgress: any;

    constructor(
        public activeModal: NgbActiveModal,
        private storageService: FireStorageService
    ) {}

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
        console.log('imageCropped');
        console.log(event.base64.substring(1, 50));
    }
    imageLoaded() {
        // show cropper
        console.log();
    }
    cropperReady() {
        // cropper ready
        console.log('cropperReady');
    }
    loadImageFailed() {
        // show message
        console.log('loadImageFailed');
    }

    uploadPhoto() {
        this.submitted = true;
        const mediaFolderPath = `${this.folder}/${this.id}/${Date.now()}.png`;

        const fileToUpload = base64ToFile(this.croppedImage);

        const {
            downloadUrl$,
            uploadProgress$,
        } = this.storageService.uploadFileAndGetMetadata(
            mediaFolderPath,
            fileToUpload
        );

        this.uploadProgress$ = uploadProgress$;

        downloadUrl$
            .pipe(
                takeUntil(this.destroy$),
                catchError((error) => {
                    console.log(`${error.message} 😢`, 'Close', {
                        duration: 4000,
                    });
                    return EMPTY;
                })
            )
            .subscribe((downloadUrl) => {
                const result = {
                    ref: mediaFolderPath,
                    url: downloadUrl,
                };
                this.submitted = false;
                console.log('finished');
                this.activeModal.close(result);
            });
    }
}
