<!-- Offcanvas Menu Begin -->
<div class="offcanvas-menu-overlay"></div>
<div class="offcanvas-menu-wrapper">
    <div class="offcanvas__close">+</div>
    <ul class="offcanvas__widget">
        <li><span class="icon_search search-switch"></span></li>
        <li><a href="#"><span class="icon_heart_alt"></span>
                <div class="tip">2</div>
            </a></li>
        <li><a href="#"><span class="icon_bag_alt"></span>
                <div class="tip">2</div>
            </a></li>
    </ul>
    <div class="offcanvas__logo">
        <a href="./index.html"><img src="../../../../assets/img/cyom-logo-text.png" alt=""></a>
    </div>
    <div id="mobile-menu-wrap"></div>
    <div class="offcanvas__auth">
        <a href="#">Login</a>
        <a href="#">Register</a>
    </div>
</div>
<!-- Offcanvas Menu End -->

<!-- Header Section Begin -->
<header class="header">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-3 col-lg-2">
                <div class="header__logo">
                    <a href="./index.html"><img src="../../../../assets/img/cyom-logo-text.png" alt=""
                            id="logo-text"></a>
                </div>
            </div>
            <div class="col-xl-6 col-lg-7">
                <nav class="header__menu">
                    <ul>
                        <li [routerLinkActive]="'active'" [routerLinkActiveOptions]="{exact:
                            true}"><a href="javascript:void(0)" [routerLink]="[ '/' ]">Home</a></li>
                        <li><a href="#">Feminino</a></li>
                        <li><a href="#">Masculino</a></li>
                        <!-- <li><a href="#">Pages</a>
                            <ul class="dropdown">
                                <li><a href="./product-details.html">Product Details</a></li>
                                <li><a href="./shop-cart.html">Shop Cart</a></li>
                                <li><a href="./checkout.html">Checkout</a></li>
                                <li><a href="./blog-details.html">Blog Details</a></li>
                            </ul>
                        </li> -->
                        <!-- <li><a href="./blog.html">Blog</a></li> -->
                        <li routerLinkActive="active"><a href="javascript:void(0)"
                                [routerLink]="[ '/contact' ]">Contato</a></li>
                    </ul>
                </nav>
            </div>
            <div class="col-lg-3">
                <div class="header__right">
                    <div class="header__right__auth">
                        <ng-template #anonymousUser>
                            <a href="javascript:void(0)" [routerLink]="[ '/auth/login' ]">Entrar</a>
                        </ng-template>

                        <a href="javascript:void(0)" *ngIf="appUser; else anonymousUser" class="dropleft"
                            (click)="$event.stopPropagation(); myDrop.open();">
                            {{appUser.name}}
                            <div ngbDropdown #myDrop="ngbDropdown"
                                class="dropdown-menu dropdown-menu-left dropdown text-center">
                                <div class="user-profile-modal">
                                    <div class="user-profile-image">
                                        <app-user-profile-image *ngIf="appUser" [imageUrl]="appUser.profileImageUrl">
                                        </app-user-profile-image>
                                    </div>
                                    <p class="h6 pt-3 mb-0">{{appUser.name}}</p>
                                    <p class="p-0 small">{{appUser.email}}</p>
                                    <div class="dropdown-divider"></div>
                                    <a href="javascript:void(0)" class="profile-btn"
                                        [routerLink]="[ '/users', appUser.id ]">Meu perfil</a>
                                    <hr class="profile">
                                    <ng-container *ngIf="isAdmin">
                                        <a href="javascript:void(0)" class="profile-btn"
                                            [routerLink]="['/admin/products/list']">Gerenciar minha conta</a>
                                        <hr class="profile">
                                    </ng-container>
                                    <a href="javascript:void(0)" class="profile-btn" (click)="logout()">Sair</a>
                                </div>
                            </div>
                        </a>
                    </div>
                    <ul class="header__right__widget">
                        <li><span class="icon_search search-switch"></span></li>
                        <li>
                            <a href="javascript:void(0)" [routerLink]="['/cart']"><span class="icon_bag_alt"></span>
                                <div class="tip" *ngIf="cart$ | async as cart">{{cart.totalItemsCount}}</div>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="canvas__open">
            <i class="fa fa-bars"></i>
        </div>
    </div>
</header>
<!-- Header Section End -->