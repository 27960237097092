import { AngularFirestore } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { BaseService } from '@shared/services/firebase/base.service';
import { Photo } from '../models/photo.model';
import { first, map, mergeMap, switchMap, take } from 'rxjs/operators';
import * as firebase from 'firebase/app';
import { combineLatest, defer, Observable, of, Subject } from 'rxjs';

@Injectable()
export class PhotoService extends BaseService<Photo> {
    constructor(afs: AngularFirestore, afg: AngularFireStorage) {
        super('photos', afs, afg);
    }

    setLike(id: string, userId: string, value: boolean): Observable<Photo> {
        console.log('[PhotoService] addLike: ', id);

        const subject = new Subject<any>();

        const item = {
            dtCreation: firebase.firestore.FieldValue.serverTimestamp(),
            userId,
        };

        const docRef = this.afs
            .collection(`photos_liked/${userId}/user_liked_photos`)
            .doc(id);

        docRef
            .snapshotChanges()
            .pipe(take(1))
            .subscribe((x) => {
                if (!x.payload.exists && value === true) {
                    docRef
                        .set(item, { merge: true })
                        .then(() => subject.next(item));
                } else if (x.payload.exists && value === false) {
                    docRef.delete().then(() => subject.next(null));
                } else {
                    subject.next(null);
                }
            });

        return subject.asObservable();
    }

    getPhotos(userId: string) {
        return this.afs
            .collection('photos')
            .valueChanges()
            .pipe(this.getLikes(userId, 'photos_liked'));
    }

    getByUser(userId: string): Observable<Photo[]> {
        const coll = this.afs.collection(this.uri, (ref) => {
            return ref.where('userId', '==', userId);
        });

        return coll.snapshotChanges().pipe(
            map((changes) => {
                return changes.map((a: any) => {
                    const data = a.payload.doc.data() as Photo;
                    data.id = a.payload.doc.id;
                    return data;
                });
            })
        );
    }

    getLikes(userId, collection) {
        return (source) =>
            defer(() => {
                let parent;

                // Operator state
                let collectionData;

                return source.pipe(
                    switchMap((data) => {
                        // Clear mapping on each emitted val ;
                        parent = data;
                        // Save the parent data state
                        collectionData = data as any[];

                        const reads$ = [];
                        for (const doc of collectionData) {
                            // Push doc read to Array

                            if (userId) {
                                reads$.push(
                                    this.afs
                                        .doc(
                                            `${collection}/${userId}/user_liked_photos/${doc.id}`
                                        )
                                        .valueChanges()
                                );
                            } else {
                                reads$.push(of([]));
                            }
                        }

                        return combineLatest(reads$);
                    }),
                    map((arr) => {
                        return collectionData.map((v, i) => {
                            return { ...v, [collection]: arr[i] || null };
                        });
                    })
                );
            });
    }
}
