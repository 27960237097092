import { Product } from '@app/modules/products/models/product.model';
import { BaseEntity } from '@shared/services/firebase/base-entity';

import { ShoppingCartItem } from './shopping-cart-item.model';

export class ShoppingCart extends BaseEntity {
    constructor(public items: ShoppingCartItem[]) {
        super();
        this.items = items;
    }

    getQuantity(product: Product) {
        const item = this.items.filter((i) => i.id === product.id)[0];
        return item ? item.quantity : 0;
    }

    get totalPrice() {
        let sum = 0;
        for (const item of this.items) {
            sum += item.totalPrice;
        }
        return sum;
    }

    get totalItemsCount() {
        let count = 0;
        for (const item of this.items) {
            count += item.quantity;
        }
        return count;
    }
}
