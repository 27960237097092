import { Observable } from 'rxjs';
import { ShoppingCartService } from '@app/modules/shopping-cart/services/shopping-cart.service';
import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '@shared/modules/auth/auth.service';
import { AppUser } from '@modules/users/models/app.user.model';
import { ShoppingCart } from '@app/modules/shopping-cart/models/shopping-cart.model';

@Component({
    selector: 'app-layout-navbar',
    templateUrl: './layout-navbar.component.html',
    styleUrls: ['./layout-navbar.component.scss'],
})
export class LayoutNavbarComponent {
    appUser: AppUser;
    faUser = faUser;
    collapsed = true;
    logoColor = '#fff';

    isNgoOwner = false;
    isAdmin: boolean;

    logoColor1 = '#000';
    logoColor2 = '#000';

    cart$: Observable<ShoppingCart>;

    constructor(
        private auth: AuthService,
        private router: Router,
        shoppingCartService: ShoppingCartService
    ) {
        auth.appUser$.subscribe((appUser) => {
            this.appUser = appUser;

            // TODO: verificar a verificação de roles
            this.isAdmin =
                this.appUser &&
                this.appUser.roles &&
                this.appUser.roles.includes('admin');

            this.isNgoOwner =
                this.appUser &&
                this.appUser.roles &&
                this.appUser.roles.includes('shop.owner');
        });

        this.cart$ = shoppingCartService.getCart();
    }

    logout(): void {
        this.auth.logout();
    }

    @HostListener('window:scroll', ['$event'])
    onWindowScroll(e): void {
        // const element = document.querySelector('.navbar');
        // if (window.pageYOffset > 600) {
        //     element.classList.add('navbar-light');
        //     element.classList.remove('navbar-dark');
        // } else {
        //     element.classList.add('navbar-dark');
        //     element.classList.remove('navbar-light');
        // }
    }

    get showAdminMenu(): boolean {
        return this.appUser.roles && this.appUser.roles.includes('admin');
    }

    profile() {
        this.router.navigate([`/users/${this.appUser.id}`]);
    }
}
