import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-uploader',
    templateUrl: './uploader.component.html',
    styleUrls: ['./uploader.component.scss'],
})
export class UploaderComponent {
    @Input() folder: string;
    @Input() dropZone: boolean;
    @Input() preview: boolean;
    @Input() type = 'button';

    @Output() finish = new EventEmitter<any>();

    isHovering: boolean;

    files: File[] = [];

    toggleHover(event: boolean) {
        this.isHovering = event;
    }

    onDrop(files: FileList) {
        for (let i = 0; i < files.length; i++) {
            this.files.push(files.item(i));
        }
    }

    onFinish(event: any) {
        this.finish.emit(event);
    }
}
