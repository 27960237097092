import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faHeart } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-like',
    templateUrl: './like.component.html',
    styleUrls: ['./like.component.scss'],
})
export class LikeComponent {
    @Input() likesCount: number;
    @Input() isActive: boolean;
    @Output() liked = new EventEmitter<boolean>();

    faHeart = faHeart;

    onClick() {
        if (!this.likesCount) {
            this.likesCount = 0;
        }
        this.likesCount += this.isActive ? -1 : 1;
        this.isActive = !this.isActive;
        this.liked.emit(this.isActive);
    }
}
