import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Product } from '@app/modules/products/models/product.model';
import { BaseService } from '@app/shared/services/firebase/base.service';
import { of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { ShoppingCartItem } from '../models/shopping-cart-item.model';
import { ShoppingCart } from '../models/shopping-cart.model';

@Injectable({
    providedIn: 'root',
})
export class ShoppingCartService extends BaseService<ShoppingCart> {
    // cartSubscription: Subscription;
    // cartItemSubscription: Subscription;

    constructor(afs: AngularFirestore, afg: AngularFireStorage) {
        super('shopping-carts', afs, afg);
    }

    // ngOnDestroy() {
    //     this.cartSubscription.unsubscribe();
    //     this.cartItemSubscription.unsubscribe();
    // }

    private getOrCreateCartId() {
        const cartId = localStorage.getItem('cartId');

        if (cartId) {
            return of(cartId);
        }

        const cart = new ShoppingCart([]);
        return this.add(cart).pipe(
            switchMap((result) => {
                localStorage.setItem('cartId', result.id);
                return result.id;
            })
        );
    }

    getCart() {
        return this.getOrCreateCartId().pipe(
            switchMap((cartId) => {
                return this.get(cartId).pipe(
                    switchMap((cart) => {
                        return this.getCartItems(cartId).pipe(
                            map((items) => {
                                const c = new ShoppingCart(items);
                                c.id = cart.id;
                                return c;
                            })
                        );
                    })
                );
            })
        );
    }

    getCartItems(cartId: string) {
        return this.afs
            .collection(`${this.uri}/${cartId}/items`)
            .snapshotChanges()
            .pipe(
                map((changes) => {
                    return changes.map((a) => {
                        const data = a.payload.doc.data() as ShoppingCartItem;
                        data.id = a.payload.doc.id;
                        return new ShoppingCartItem(data);
                    });
                })
            );
    }

    addToCart(product: Product) {
        this.updateItem(product, 1);
    }

    removeFromCart(product: Product) {
        this.updateItem(product, -1);
    }

    excludeFromCart(cart: ShoppingCart, product: Product) {
        const qty = cart.getQuantity(product);
        console.log(`Remover ${qty} quantidades do carrinho`);
        this.updateItem(product, -qty);
    }

    // TODO: criar function para limpar o carrinho:
    // fonte: https://firebase.google.com/docs/firestore/solutions/delete-collections
    clearCart() {
        this.getOrCreateCartId()
            .pipe(take(1))
            .subscribe((cartId) => {
                this.getCartItems(cartId)
                    .pipe(take(1))
                    .subscribe((items) => {
                        for (const item of items) {
                            console.log(item);
                            this.afs
                                .collection(`${this.uri}/${cartId}/items`)
                                .doc(item.id)
                                .delete();
                        }
                    });
            });
    }

    private getItem(cartId: string, productId: string) {
        return this.afs
            .collection(`${this.uri}/${cartId}/items`)
            .doc(productId);
    }

    private updateItem(product: Product, change: number) {
        this.getOrCreateCartId().subscribe((cartId) => {
            const itemRef = this.getItem(cartId, product.id);
            const item$ = itemRef.get();
            item$.pipe(take(1)).subscribe((item) => {
                const sc = item.data() as ShoppingCartItem;
                const quantity = ((sc && sc.quantity) || 0) + change;

                if (quantity === 0) {
                    itemRef.delete();
                } else {
                    itemRef.set(
                        {
                            name: product.name,
                            thumbUrl: product.thumbUrl,
                            price: product.price * 1,
                            quantity,
                        },
                        { merge: true }
                    );
                }
            });
        });
    }
}
