import { Component, Input, ViewChild } from '@angular/core';
import { AngularFireUploadTask } from '@angular/fire/storage';
import { FireStorageService } from '@app/shared/services/firebase/fire-storage.service';
import { Observable, Subject } from 'rxjs';
import { PhotoService } from '@app/modules/photos/services/photo.service';

@Component({
    selector: 'app-image-uploader',
    templateUrl: './image-uploader.component.html',
    styleUrls: ['./image-uploader.component.scss'],
})
export class ImageUploaderComponent {
    @ViewChild('imageupload')
    destroy$: Subject<null> = new Subject();

    fileName: string;
    submitted = false;
    uploadProgress$: Observable<number>;

    @Input() imageChangedEvent: any = '';
    @Input() folder: string;
    @Input() file: File;
    @Input() id: string;

    task: AngularFireUploadTask;

    percentage: Observable<number>;
    snapshot: Observable<any>;
    downloadURL: string;

    uploadProgress: any;

    constructor(
        private userPhotoService: PhotoService,
        private storageService: FireStorageService
    ) {}

    upload($event: any) {
        this.submitted = true;

        const mediaFolderPath = `${this.folder}/${this.id}/images/${$event.target.files[0].name}`;

        const {
            downloadUrl$,
            uploadProgress$,
        } = this.storageService.uploadFileAndGetMetadata(
            mediaFolderPath,
            $event.target.files[0]
        );

        this.uploadProgress$ = uploadProgress$;

        downloadUrl$.subscribe((downloadUrl) => {
            const photo = {
                imgRef: mediaFolderPath,
                imgUrl: downloadUrl,
                userId: this.id,
            };

            this.userPhotoService.add(photo);
            this.submitted = false;
            console.log('finished');
        });
    }
}
