<div class="container py-4">
    <div class="row mb-4">
        <div class="col">
            <image-cropper #cropper class="cropper" [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                [aspectRatio]="aspectRatio ? aspectRatio : 1/1" format="png" (imageCropped)="imageCropped($event)"
                (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()">
            </image-cropper>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <button class="btn btn-primary" (click)="uploadPhoto()">Recortar e salvar</button>
            <button class="btn btn-secondary ml-4" (click)="activeModal.close('Close click')">Cancelar</button>
        </div>
    </div>
</div>


<!-- <img [src]="croppedImage" /> -->