import { AngularFirestore } from '@angular/fire/firestore';
import { AppUser } from './../models/app.user.model';
import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { BaseService } from '@shared/services/firebase/base.service';
import { MediaLink } from '../models/media-link.model';

@Injectable()
export class UserService extends BaseService<AppUser> {
    constructor(afs: AngularFirestore, afg: AngularFireStorage) {
        super('users', afs, afg);
    }

    getByEmail(email: string) {
        return super.getByField('email', email);
    }

    saveMediaLink(id, mediaLink: MediaLink) {
        this.afs.collection(`users/${id}/videos`).add(mediaLink);
    }

    // Custom Methods
    // Sample
    // getForUser(userId: string): Observable<Materia[]> {
    //   console.info("[CartService] getForUser: ", userId);
    //   let coll = this.afs.collection(this.uri, ref => ref.where("userId", "==", userId));

    //   return coll
    //      .snapshotChanges()
    //      .map(changes => {
    //     return changes.map(a => {
    //       const data = a.payload.doc.data() as Materia;
    //       data.id = a.payload.doc.id;
    //       return data;
    //     });
    //   });
    // }
}
