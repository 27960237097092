import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from './../../shared/shared.module';
import { LayoutBlankComponent } from './layout-blank/layout-blank.component';
import { LayoutWithNavbarComponent } from './layout-with-navbar/layout-with-navbar.component';
import { LayoutWithoutNavbarComponent } from './layout-without-navbar/layout-without-navbar.component';
import { NavbarComponent } from './navbar/navbar.component';

@NgModule({
    declarations: [
        LayoutWithNavbarComponent,
        LayoutWithoutNavbarComponent,
        LayoutBlankComponent,
        NavbarComponent,
    ],
    imports: [CommonModule, RouterModule, SharedModule, NgbModule],
})
export class LayoutModule {}
