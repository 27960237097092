import { AppUser } from '@modules/users/models/app.user.model';
import { UserService } from '@modules/users/services/user.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import * as firebase from 'firebase';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class AuthService {
    user$: Observable<firebase.User>;
    // public appUser$: Observable<AppUser>;

    constructor(
        private userService: UserService,
        private afAuth: AngularFireAuth,
        private route: ActivatedRoute,
        private router: Router
    ) {
        this.user$ = afAuth.authState;
    }

    login(email: string, password: string): any {
        const returnUrl =
            this.route.snapshot.queryParamMap.get('returnUrl') || '/';

        return this.afAuth
            .signInWithEmailAndPassword(email, password)
            .then(() => {
                this.router.navigate([returnUrl]);
            });
    }

    register(name: string, email: string, password: string) {
        return this.afAuth
            .createUserWithEmailAndPassword(email, password)
            .then((user) => {
                const newUser: AppUser = {
                    id: user.user.uid,
                    name,
                    email: user.user.email,
                    roles: [],
                };
                this.userService.add(newUser);
                return newUser;
            });
    }

    logout() {
        this.afAuth.signOut();
    }

    get appUser$(): Observable<AppUser> {
        return this.user$.pipe(
            switchMap((user) => {
                if (user) {
                    return this.userService.getByEmail(user.email);
                }
                return of(null);
            })
        );
    }
}
